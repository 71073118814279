@layer base {
  .main {
  padding: var(--sk-space-24);
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
}

.editLinkWrapper {
  display: flex;
  justify-content: flex-end;
}

.information {
  margin-top: calc(-1 * var(--sk-space-48));
  padding: 0 var(--sk-space-56);
  text-align: center;
}

.profilePictureWrapper {
  display: flex;
  position: relative;
  justify-content: center;
}

.profilePicture {
  object-fit: cover;
}

.careerCenterLogo {
  position: absolute;
  right: 0;
  bottom: 0;
}

.name {
  margin-top: var(--sk-space-20);
}

.studiesInformation {
  margin-top: var(--sk-space-4);
}

.contactInformation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-12);
}

.socialMedia {
  margin-top: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .information {
    margin-top: calc(-1 * var(--sk-space-32));
  }
}

}